<template>
  <div>
    <!-- modal vertical center -->
    <b-sidebar
      :visible="active"
      bg-variant="white"
      size="lg"
      :ok-disabled="isLoading"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      right
      no-header
      @ok="handleOk"
      @change="(val) => $emit('update:active', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Thêm mới câu hỏi</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <b-overlay :show="isLoading" block opacity="0.6" spinner-variant="primary">
          <validation-observer #default="{ handleSubmit }" ref="formQuestion">
            <b-form class="p-2" @submit.prevent="handleSubmit(handleOk)">
              <b-form-group label="Tiêu đề câu hỏi" label-for="question-name">
                <validation-provider #default="{ errors }" name="Question Title" rules="required">
                  <b-form-input
                    id="question-name"
                    v-model="name"
                    type="text"
                    placeholder="Tiêu đề câu hỏi"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Loại câu hỏi" label-for="question-type">
                <v-select
                  id="question-type"
                  v-model="questionType"
                  :options="questionTypes"
                  label="title"
                  :clearable="false"
                >
                  <template #option="{ title, icon }">
                    <feather-icon :icon="icon" size="16" class="align-middle mr-50" />
                    <span> {{ title }}</span>
                  </template>
                </v-select>
              </b-form-group>
              <b-form-group label="Nhóm" label-for="question-group">
                <v-select
                  id="question-group"
                  v-model="questionGroup"
                  :options="questionGroups"
                  label="title"
                  :clearable="false"
                />
              </b-form-group>
              <b-form-group>
                <template v-if="questionType.value === 'text'">
                  <b-form-textarea size="sm" type="text" placeholder="Trả lời chữ" readonly />
                </template>
                <template v-if="questionType.value === 'date'">
                  <b-input-group size="sm">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <b-form-input readonly placeholder="DD/MM/YYYY" />
                  </b-input-group>
                </template>
                <template v-if="questionType.value === 'check'">
                  <b-input-group
                    v-for="(option, index) in options"
                    :key="index"
                    size="sm"
                    class="mb-1"
                  >
                    <b-input-group-prepend is-text>
                      <b-form-checkbox plain disabled />
                    </b-input-group-prepend>
                    <b-form-input v-model="option.text" />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeOption(option)"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-input-group size="sm">
                    <b-input-group-prepend is-text>
                      <b-form-checkbox plain disabled />
                    </b-input-group-prepend>
                    <b-form-input ref="add-option" placeholder="Thên tùy chọn" @click="addOption" />
                  </b-input-group>
                </template>
                <template v-if="questionType.value === 'radio'">
                  <b-input-group
                    v-for="(option, index) in options"
                    :key="index"
                    size="sm"
                    class="mb-1"
                  >
                    <b-input-group-prepend is-text>
                      <b-form-radio plain disabled />
                    </b-input-group-prepend>
                    <b-form-input v-model="option.text" />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeOption(option)"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-input-group size="sm">
                    <b-input-group-prepend is-text>
                      <b-form-radio plain disabled />
                    </b-input-group-prepend>
                    <b-form-input ref="add-option" placeholder="Thên tùy chọn" @click="addOption" />
                  </b-input-group>
                </template>
                <b-form-checkbox
                  v-model="selected"
                  checked="true"
                  class="custom-control-primary mt-2"
                  switch
                >
                  Bắt buộc
                </b-form-checkbox>
              </b-form-group>
              <div class="d-flex mt-2">
                <b-button variant="primary" class="mr-2" type="submit"> Lưu </b-button>
                <b-button type="button" variant="outline-secondary" @click="hide"> Hủy </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import {
  BModal,
  BForm,
  BOverlay,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BCol,
  BRow,
  BFormCheckbox,
  BFormRadio,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BSidebar,
  BButton,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { create } from '@/apis/apiQuestion';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BForm,
    BOverlay,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BFormCheckbox,
    BFormRadio,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BSidebar,
    vSelect,
    BFormGroup,
    BButton,
  },
  model: {
    prop: 'active',
    event: 'update:active',
  },
  props: {
    t: {
      type: Function,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      questionType: {
        title: 'Trả lời chữ',
        icon: 'ApertureIcon',
        value: 'text',
      },
      questionTypes: [
        {
          title: 'Trả lời chữ',
          icon: 'AlignLeftIcon',
          value: 'text',
        },
        {
          title: 'Một lựa chọn',
          icon: 'CheckCircleIcon',
          value: 'radio',
        },
        {
          title: 'Nhiều lựa chọn ',
          icon: 'CheckSquareIcon',
          value: 'check',
        },
        {
          title: 'Ngày ',
          icon: 'CalendarIcon',
          value: 'date',
        },
      ],
      questionGroup: { title: 'Thẩm tele', value: 'tele' },
      questionGroups: [
        { title: 'Thẩm tele', value: 'tele' },
        { title: 'Thẩm tham chiếu', value: 'refer' },
        { title: 'Thẩm thực địa', value: 'local' },
      ],
      options: [
        {
          text: 'Tùy chọn 1',
          key: 1,
        },
      ],
      name: '',
      selected: false,
      isLoading: false,
    };
  },
  methods: {
    addOption() {
      this.$refs['add-option'].blur();

      this.options.push({
        text: `Tùy chọn ${this.options.length + 1}`,
        key: this.options.length + 1,
      });
    },
    removeOption(option) {
      this.options = this.options.filter((item) => item !== option);

      if (this.options.length === 0) {
        this.options = [
          {
            text: 'Tùy chọn 1',
            key: 1,
          },
        ];
      }
    },
    // handleOk(bvModalEvt) {
    //   console.log('vao');
    //   // Prevent modal from closing
    //   bvModalEvt.preventDefault();
    //   // Trigger submit handler
    //   this.handleSubmit();
    // },
    handleOk() {
      this.$refs.formQuestion.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          const { name, questionType, questionGroup, selected, options } = this;
          const type = questionType.value;

          let optionSend;
          if (type === 'radio' || type === 'check') {
            optionSend = {};
            options.forEach((item) => {
              optionSend[item.key] = {
                value: item.text,
                status: true,
              };
            });
          } else {
            optionSend = null;
          }

          const data = {
            group: questionGroup.value,
            options: optionSend,
            required: selected,
            type,
            title: name,
          };
          create(data)
            .then(() => {
              this.isLoading = false;
              // Hide the modal manually
              this.$emit('update:active', false);
              this.$emit('add-new-success');
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
        }
      });
    },
  },
};
</script>
