import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { list } from '@/apis/apiQuestion';

export default function useQuestionList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);
  const { t } = useI18nUtils();

  // Table Handlers
  const tableColumns = [
    { key: 'title', label: t('Question'), sortable: true },
    {
      key: 'group',
      label: t('Group'),
      sortable: true,
    },
    {
      key: 'updatedAt',
      label: t('Update at'),
      formatter: title,
      sortable: true,
    },
    { key: 'actions', label: t('Actions') },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const sortBy = ref('updatedAt');
  const isSortDirDesc = ref(true);
  const groupFilter = ref(null);
  const nameFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, groupFilter, nameFilter], () => {
    refetchData();
  });

  const fetchUsers = (ctx, callback) => {
    list({
      page: currentPage.value,
      limit: perPage.value,
      title: nameFilter.value,
      group: groupFilter.value,
      order: sortBy.value,
      sortOrder: isSortDirDesc.value ? -1 : 1,
    })
      .then((response) => {
        const { data, meta } = response.data;

        callback(data);
        totalUsers.value = meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Lỗi tải danh sách câu hỏi',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,

    // Extra Filters
    groupFilter,
    nameFilter,

    // i18n
    t,
  };
}
