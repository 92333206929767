<template>
  <div>
    <!-- modal vertical center -->
    <b-modal
      id="modal-view"
      centered
      :title="t('View question')"
      cancel-variant="outline-secondary"
      :cancel-title="t('Edit')"
      :ok-title="t('Close')"
      size="lg"
      @cancel="handleCancel"
    >
      <b-overlay :show="isLoading" block opacity="0.6" spinner-variant="primary">
        <b-row>
          <b-col md="7" class="d-flex align-items-center">
            <h4>{{ data.title }}</h4>
          </b-col>
          <b-col class="mb-1">
            <label for="question-group">Nhóm</label>
            <v-select
              id="question-group"
              disabled
              :value="questionGroups.find((item) => item.value === data.group)"
              :options="questionGroups"
              label="title"
              :clearable="false"
            />
          </b-col>
        </b-row>
        <b-row>
          <template v-if="data.type === 'text'">
            <b-col md="7">
              <b-form-textarea size="sm" type="text" placeholder="Trả lời chữ" readonly />
            </b-col>
          </template>
          <template v-if="data.type === 'date'">
            <b-col md="7">
              <b-input-group size="sm">
                <b-input-group-prepend is-text>
                  <feather-icon icon="CalendarIcon" />
                </b-input-group-prepend>
                <b-form-input readonly placeholder="DD/MM/YYYY" />
              </b-input-group>
            </b-col>
          </template>
          <template v-if="data.type === 'check'">
            <b-col md="7">
              <b-input-group v-for="(option, index) in options" :key="index" size="sm" class="mb-1">
                <b-input-group-prepend is-text>
                  <b-form-checkbox plain disabled />
                </b-input-group-prepend>
                <b-form-input readonly :value="option.text" />
              </b-input-group>
            </b-col>
          </template>
          <template v-if="data.type === 'radio'">
            <b-col md="7">
              <b-input-group v-for="(option, index) in options" :key="index" size="sm" class="mb-1">
                <b-input-group-prepend is-text>
                  <b-form-radio plain disabled />
                </b-input-group-prepend>
                <b-form-input readonly :value="option.text" />
              </b-input-group>
            </b-col>
          </template>
          <b-col>
            <b-form-checkbox
              :checked="data.required"
              disabled
              class="custom-control-primary"
              switch
            >
              Bắt buộc
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BOverlay,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BFormCheckbox,
  BFormRadio,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import { show } from '@/apis/apiQuestion';
import vSelect from 'vue-select';

export default {
  components: {
    BModal,
    BOverlay,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BFormCheckbox,
    BFormRadio,
    BInputGroup,
    BInputGroupPrepend,

    vSelect,
  },
  props: {
    questionId: {
      type: String,
      required: true,
    },
    t: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      questionTypes: [
        {
          title: 'Trả lời chữ',
          icon: 'AlignLeftIcon',
          value: 'text',
        },
        {
          title: 'Một lựa chọn',
          icon: 'CheckCircleIcon',
          value: 'radio',
        },
        {
          title: 'Nhiều lựa chọn ',
          icon: 'CheckSquareIcon',
          value: 'check',
        },
        {
          title: 'Ngày ',
          icon: 'CalendarIcon',
          value: 'date',
        },
      ],
      questionGroup: { title: 'Thẩm tele', value: 'tele' },
      questionGroups: [
        { title: 'Thẩm tele', value: 'tele' },
        { title: 'Thẩm tham chiếu', value: 'refer' },
        { title: 'Thẩm thực địa', value: 'local' },
      ],
      options: [
        {
          text: 'Tùy chọn 1',
          key: 1,
        },
      ],
      data: {},
      name: '',
      selected: false,
      isLoading: false,
    };
  },
  watch: {
    questionId(val) {
      if (val) {
        this.getData(val);
      }
    },
  },
  methods: {
    handleCancel() {
      this.$emit('edit-question', this.questionId);
    },
    getData(id) {
      this.isLoading = true;
      show(id)
        .then((res) => {
          const { data } = res.data;
          this.data = data;
          const { options } = data;
          if (options) {
            const newOptions = [];
            const keys = Object.keys(options);
            keys.forEach((key) => {
              if (options[key].status) {
                newOptions.push({
                  text: options[key].value,
                  key,
                });
              }
            });
            this.options = newOptions;
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          console.log('err');
        });
    },
  },
};
</script>
