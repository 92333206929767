var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('users-list-filters',{attrs:{"name-filter":_vm.nameFilter,"group-filter":_vm.groupFilter,"group-options":_vm.groupOptions,"t":_vm.t},on:{"update:nameFilter":function($event){_vm.nameFilter=$event},"update:name-filter":function($event){_vm.nameFilter=$event},"update:groupFilter":function($event){_vm.groupFilter=$event},"update:group-filter":function($event){_vm.groupFilter=$event},"clear-filter":_vm.clearFilter}}),_c('question-list-add',{attrs:{"active":_vm.isAddRole,"t":_vm.t},on:{"update:active":function($event){_vm.isAddRole=$event},"add-new-success":_vm.addNewSuccess}}),_c('question-list-view',{attrs:{"t":_vm.t,"question-id":_vm.questionIdView},on:{"edit-question":_vm.editQuestion}}),_c('question-list-edit',{attrs:{"t":_vm.t,"question-id":_vm.questionIdEdit},on:{"edit-success":_vm.editSuccess}}),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Show")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("entries")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(
                _vm.$can(
                  _vm.permissionCode.QUESTION_CREATE.action,
                  _vm.permissionCode.QUESTION_CREATE.resource
                )
              )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function () { return (_vm.isAddRole = true); }}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.t('Add question')))])]):_vm._e()],1)])],1)],1),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"items":_vm.fetchUsers,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(title)",fn:function(data){return [_c('div',{staticClass:"ellipsesText"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(group)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getGroupValue(data.value))+" ")]}},{key:"cell(actions)",fn:function(data){return [(
            _vm.$can(_vm.permissionCode.QUESTION_DETAIL.action, _vm.permissionCode.QUESTION_DETAIL.resource)
          )?_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-success"},on:{"click":function($event){return _vm.viewQuestion(data.item.id)}}},[_c('feather-icon',{directives:[{name:"b-modal",rawName:"v-b-modal.question-detail",modifiers:{"question-detail":true}}],attrs:{"id":("invoice-row-" + (data.item.id) + "-preview-icon"),"icon":"EyeIcon","size":"16"}})],1):_vm._e(),_c('b-tooltip',{attrs:{"title":_vm.t('View question'),"target":("invoice-row-" + (data.item.id) + "-preview-icon")}}),_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(
              _vm.$can(_vm.permissionCode.QUESTION_UPDATE.action, _vm.permissionCode.QUESTION_UPDATE.resource)
            )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.editQuestion(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v(_vm._s(_vm.t('Edit')))])],1):_vm._e(),(
              _vm.$can(_vm.permissionCode.QUESTION_DELETE.action, _vm.permissionCode.QUESTION_DELETE.resource)
            )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteQuestion(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v(_vm._s(_vm.t('Delete')))])],1):_vm._e()],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" entries")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalUsers,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }