import jwt from '@/auth/jwt/useJwt';

export function show(id) {
  return jwt.axiosIns.get(`questions/${id}`);
}

export function remove(id) {
  return jwt.axiosIns.delete(`questions/${id}`);
}

export function create(data) {
  return jwt.axiosIns.post('questions', data);
}

export function edit(id, data) {
  return jwt.axiosIns.put(`questions/${id}`, data);
}

export function list(pagination) {
  return jwt.axiosIns.get('questions', {
    params: {
      page: 1,
      limit: 10,
      ...pagination,
    },
  });
}
