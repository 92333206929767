<template>
  <div>
    <!-- Filters -->
    <users-list-filters
      :name-filter.sync="nameFilter"
      :group-filter.sync="groupFilter"
      :group-options="groupOptions"
      :t="t"
      @clear-filter="clearFilter"
    />

    <question-list-add :active.sync="isAddRole" :t="t" @add-new-success="addNewSuccess" />
    <question-list-view :t="t" :question-id="questionIdView" @edit-question="editQuestion" />
    <question-list-edit :t="t" :question-id="questionIdEdit" @edit-success="editSuccess" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="
                  $can(
                    permissionCode.QUESTION_CREATE.action,
                    permissionCode.QUESTION_CREATE.resource,
                  )
                "
                variant="primary"
                @click="() => (isAddRole = true)"
              >
                <span class="text-nowrap">{{ t('Add question') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Title -->
        <template #cell(title)="data">
          <div class="ellipsesText">
            {{ data.value }}
          </div>
        </template>
        <!-- Column: Group -->
        <template #cell(group)="data">
          {{ getGroupValue(data.value) }}
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="
              $can(permissionCode.QUESTION_DETAIL.action, permissionCode.QUESTION_DETAIL.resource)
            "
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="viewQuestion(data.item.id)"
          >
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              v-b-modal.question-detail
              icon="EyeIcon"
              size="16"
            />
          </b-button>
          <b-tooltip
            :title="t('View question')"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item
              v-if="
                $can(permissionCode.QUESTION_UPDATE.action, permissionCode.QUESTION_UPDATE.resource)
              "
              @click="editQuestion(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ t('Edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                $can(permissionCode.QUESTION_DELETE.action, permissionCode.QUESTION_DELETE.resource)
              "
              @click="deleteQuestion(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ t('Delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import constRouter from '@/constants/constRouter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { remove } from '@/apis/apiQuestion';
import { ref } from '@vue/composition-api';
import permissionCode from '@/constants/permissionCode';
import QuestionListAdd from './QuestionListAdd.vue';
import QuestionListView from './QuestionListView.vue';
import QuestionListEdit from './QuestionListEdit.vue';

import UsersListFilters from './QuestionListFilters.vue';
import useQuestionList from './useQuestionList';

export default {
  components: {
    QuestionListAdd,
    QuestionListView,
    QuestionListEdit,

    UsersListFilters,

    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  setup() {
    const groupOptions = [
      { label: 'Thẩm tele', value: 'tele' },
      { label: 'Thểm tham chiếu', value: 'refer' },
      { label: 'Thểm thực địa', value: 'local' },
    ];
    const isAddRole = ref(false);
    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Extra Filters
      nameFilter,
      groupFilter,

      // i18n
      t,
    } = useQuestionList();

    return {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      groupOptions,

      // Extra Filters
      nameFilter,
      groupFilter,

      constRouter,

      // i18n
      t,
      isAddRole,
      permissionCode,
    };
  },
  data: () => ({
    questionIdView: '',
    questionIdEdit: '',
  }),
  methods: {
    clearFilter() {
      this.nameFilter = '';
      this.groupFilter = '';

      this.refetchData();
    },
    addNewSuccess() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: this.$t('Add question'),
          icon: 'CheckCircleIcon',
          variant: 'success',
          text: 'Thêm câu hỏi thành công!',
        },
      });
      this.refetchData();
    },
    editSuccess() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: this.$t('Edit question'),
          icon: 'CheckCircleIcon',
          variant: 'success',
          text: 'Sửa câu hỏi thành công!',
        },
      });
      this.refetchData();
      this.questionIdView = '';
    },
    getGroupValue(value) {
      const groupOptions = this.groupOptions.filter((item) => item.value === value);
      if (groupOptions) {
        return groupOptions[0].label;
      }
      return null;
    },
    deleteQuestion(id) {
      remove(id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Xóa câu hỏi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Xóa câu hỏi thành công!',
            },
          });
          this.refetchData();
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Xóa câu hỏi',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Xóa câu hỏi thất bại!',
            },
          });
        });
    },
    viewQuestion(id) {
      this.questionIdView = id;
      this.$bvModal.show('modal-view');
    },
    editQuestion(id) {
      this.questionIdEdit = id;
      this.$bvModal.show('modal-edit');
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.ellipsesText {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
