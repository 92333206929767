<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ t('Filter') }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row class="align-items-end">
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>{{ t('Question') }}</label>
          <b-form-input
            :value="nameFilter"
            class="d-inline-block mr-1"
            placeholder="Search..."
            @input="(val) => $emit('update:nameFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>{{ t('Group') }}</label>
          <v-select
            :value="groupFilter"
            :options="groupOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:groupFilter', val)"
          />
        </b-col>
        <b-col>
          <b-button variant="primary" @click="$emit('clear-filter')">
            <span class="text-nowrap">{{ t('Cancel') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BButton, BCardHeader, BCardBody, BRow, BCol, BFormInput } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BCardBody,
    BFormInput,

    vSelect,
  },
  props: {
    nameFilter: {
      type: [String, null],
      default: null,
    },
    groupFilter: {
      type: [String, null],
      default: null,
    },
    groupOptions: {
      type: Array,
      required: true,
    },
    t: {
      type: Function,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
