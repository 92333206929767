<template>
  <div>
    <!-- modal vertical center -->
    <b-modal
      id="modal-edit"
      centered
      :title="t('Edit question')"
      cancel-variant="outline-secondary"
      :cancel-title="t('Close')"
      :ok-title="t('Edit')"
      size="lg"
      :ok-disabled="isLoading"
      @ok="handleOk"
    >
      <b-overlay :show="isLoading" block opacity="0.6" spinner-variant="primary">
        <validation-observer ref="formQuestion">
          <b-form>
            <b-row>
              <b-col md="5" class="mb-1">
                <label for="question-name">Tiêu đề câu hỏi</label>
                <validation-provider #default="{ errors }" name="Question Title" rules="required">
                  <b-form-input
                    id="question-name"
                    v-model="name"
                    type="text"
                    placeholder="Tiêu đề câu hỏi"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col>
                <label for="question-type">Loại câu hỏi</label>
                <v-select
                  id="question-type"
                  v-model="questionType"
                  :options="questionTypes"
                  label="title"
                  :clearable="false"
                >
                  <template #option="{ title, icon }">
                    <feather-icon :icon="icon" size="16" class="align-middle mr-50" />
                    <span> {{ title }}</span>
                  </template>
                </v-select>
              </b-col>
              <b-col>
                <label for="question-group">Nhóm</label>
                <v-select
                  id="question-group"
                  v-model="questionGroup"
                  :options="questionGroups"
                  label="title"
                  :clearable="false"
                />
              </b-col>
            </b-row>
            <b-row>
              <template v-if="questionType.value === 'text'">
                <b-col md="5">
                  <b-form-textarea size="sm" type="text" placeholder="Trả lời chữ" readonly />
                </b-col>
              </template>
              <template v-if="questionType.value === 'date'">
                <b-col md="5">
                  <b-input-group size="sm">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <b-form-input readonly placeholder="DD/MM/YYYY" />
                  </b-input-group>
                </b-col>
              </template>
              <template v-if="questionType.value === 'check'">
                <b-col md="5">
                  <b-input-group
                    v-for="(option, index) in options"
                    :key="index"
                    size="sm"
                    class="mb-1"
                  >
                    <b-input-group-prepend is-text>
                      <b-form-checkbox plain disabled />
                    </b-input-group-prepend>
                    <b-form-input v-model="option.text" />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeOption(option)"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-input-group size="sm">
                    <b-input-group-prepend is-text>
                      <b-form-checkbox plain disabled />
                    </b-input-group-prepend>
                    <b-form-input ref="add-option" placeholder="Thên tùy chọn" @click="addOption" />
                  </b-input-group>
                </b-col>
              </template>
              <template v-if="questionType.value === 'radio'">
                <b-col md="5">
                  <b-input-group
                    v-for="(option, index) in options"
                    :key="index"
                    size="sm"
                    class="mb-1"
                  >
                    <b-input-group-prepend is-text>
                      <b-form-radio plain disabled />
                    </b-input-group-prepend>
                    <b-form-input v-model="option.text" />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeOption(option)"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-input-group size="sm">
                    <b-input-group-prepend is-text>
                      <b-form-radio plain disabled />
                    </b-input-group-prepend>
                    <b-form-input ref="add-option" placeholder="Thên tùy chọn" @click="addOption" />
                  </b-input-group>
                </b-col>
              </template>
              <b-col>
                <b-form-checkbox
                  v-model="selected"
                  checked="true"
                  class="custom-control-primary"
                  switch
                >
                  Bắt buộc
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import {
  BModal,
  BForm,
  BOverlay,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BFormCheckbox,
  BFormRadio,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { edit, show } from '@/apis/apiQuestion';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BForm,
    BOverlay,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BFormCheckbox,
    BFormRadio,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,

    vSelect,
  },
  props: {
    questionId: {
      type: String,
      required: true,
    },
    t: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
      questionType: {
        title: 'Trả lời chữ',
        icon: 'ApertureIcon',
        value: 'text',
      },
      questionTypes: [
        {
          title: 'Trả lời chữ',
          icon: 'AlignLeftIcon',
          value: 'text',
        },
        {
          title: 'Một lựa chọn',
          icon: 'CheckCircleIcon',
          value: 'radio',
        },
        {
          title: 'Nhiều lựa chọn ',
          icon: 'CheckSquareIcon',
          value: 'check',
        },
        {
          title: 'Ngày ',
          icon: 'CalendarIcon',
          value: 'date',
        },
      ],
      questionGroup: { title: 'Thẩm tele', value: 'tele' },
      questionGroups: [
        { title: 'Thẩm tele', value: 'tele' },
        { title: 'Thẩm tham chiếu', value: 'refer' },
        { title: 'Thẩm thực địa', value: 'local' },
      ],
      options: [
        {
          text: 'Tùy chọn 1',
          key: 1,
        },
      ],
      deleteOptions: [],
      name: '',
      selected: false,
      maxKeyIndex: 0,
      isLoading: false,
    };
  },
  watch: {
    questionId() {
      this.getData(this.questionId);
    },
  },
  methods: {
    getData(id) {
      this.isLoading = true;
      show(id)
        .then((res) => {
          const { data } = res.data;
          this.data = data;
          const { options } = data;
          if (options) {
            const newOptions = [];
            const newOptionsDelete = [];
            const keys = Object.keys(options).map((item) => +item);
            keys.forEach((key) => {
              if (options[key].status) {
                newOptions.push({
                  text: options[key].value,
                  key,
                });
              } else {
                newOptionsDelete.push({
                  text: options[key].value,
                  key,
                });
              }
            });
            this.name = data.title;
            this.selected = data.required;
            this.questionGroup = this.questionGroups.find((item) => item.value === data.group);
            this.questionType = this.questionTypes.find((item) => item.value === data.type);
            this.options = newOptions;
            this.deleteOptions = newOptionsDelete;
            this.maxKeyIndex = Math.max(...keys);
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    addOption() {
      this.$refs['add-option'].blur();

      this.options.push({
        text: `Tùy chọn ${this.maxKeyIndex + 1}`,
        key: this.maxKeyIndex + 1,
      });
      this.maxKeyIndex += 1;
    },
    removeOption(option) {
      this.options = this.options.filter((item) => item !== option);
      this.deleteOptions.push(option);

      if (this.options.length === 0) {
        this.options = [
          {
            text: 'Tùy chọn 1',
            key: 0,
          },
        ];
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    convertArrayToObject(array, key) {
      const initialValue = {};
      return array.reduce(
        (obj, item) => ({
          ...obj,
          [item[key]]: {
            value: item.text,
            status: true,
          },
        }),
        initialValue,
      );
    },
    handleSubmit() {
      this.$refs.formQuestion.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          const {
            questionId,
            name,
            questionType,
            questionGroup,
            selected,
            options,
            deleteOptions,
          } = this;
          const optionSend = {};
          options.forEach((item) => {
            // replace default key 0
            if (item.key === 0) {
              optionSend[1] = {
                value: item.text,
                status: true,
              };
            } else {
              optionSend[item.key] = {
                value: item.text,
                status: true,
              };
            }
          });

          // remove default key 0
          deleteOptions.filter((obj) => obj.key !== 0);
          const deleteOptionsSend = this.convertArrayToObject(deleteOptions, 'key');
          const data = {
            group: questionGroup.value,
            options: optionSend,
            deleteOptions: deleteOptionsSend,
            required: selected,
            type: questionType.value,
            title: name,
          };
          edit(questionId, data)
            .then(() => {
              this.isLoading = false;
              // Hide the modal manually
              this.$nextTick(() => {
                this.$bvModal.hide('modal-edit');
              });
              this.$emit('edit-success');
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
        }
      });
    },
  },
};
</script>
